export async function computeTotal(purchaseId, payload) {
  const body = JSON.stringify(payload);
  const request = new Request(`/api/purchases/${purchaseId}/compute_total`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    // mode: "cors",
    // cache: "default",
    body,
  });
  const res = await fetch(request);
  const content = await res.json();
  return content.total;
}
