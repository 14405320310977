import { getField } from 'vuex-map-fields';

export default {
  getField,
  loadMatchesApiOptions(state, getters) {
    // console.dir(state.zone.type)
    const hasSmallZone = state.zone && ['city', 'commune'].includes(state.zone.type);
    const useGeoCenter = state.geocoding || hasSmallZone;

    const levels = state.filters.level.filter((l) => l !== 'n/a').map((l) => l.split('/').pop());

    return {
      // Pagination
      page: state.paginator.page,
      limit: 15,

      // We are not calculating distances and geopoint on large zones
      ...(useGeoCenter
        ? {
            geocenter: getters.geocenter,
            distance: state.filters.distance,
          }
        : {}),

      // geocenter: getters.geocenter,
      ...(state.sport ? { sport: state.sport } : {}),
      ...(state.zone && !useGeoCenter ? { 'product.center.zone': state.zone.id } : {}),

      ...(state.filters?.date
        ? {
            'dateFrom[after]': new Date(getters.matchParsedDateFilter),
            'dateFrom[before]': new Date(getters.matchParsedDateFilter + 24 * 60 * 60 * 1000),
          }
        : {}),

      ...(state.filters?.gender ? { gender: state.filters.gender } : {}),
      ...(levels ? { level: levels } : {}),
      future: 1,
    };
  },

  matchParsedDateFilter(state) {
    return state.filters.date.valueOf() ?? state.filters.date;
  },

  selectedTimeSlotMatches(state) {
    const { start, end } = state.selectedTimeSlot;
    return state.results?.filter((m) => m.date_from <= start && m.date_to >= end);
  },
  filtersCount(state) {
    return Object.entries(state.filters).reduce((acc, val) => {
      console.log(val);
      if (!val[1] || val[0] === 'distance' || (val[0] === 'level' && val[1][0] === 'n/a')) {
        return acc;
      }
      return acc + 1;
    }, 0);
  },
  geocenter(state) {
    // console.dir(state.geocoding);
    if (state.geocoding) {
      console.debug('geocenter : using state.geocoding');
      return [state.geocoding.lat, state.geocoding.lng];
    }

    if (state.zone && state.zone.type !== 'country' && state.zone.latitude) {
      console.debug('geocenter : using zone geopoint');
      return [state.zone.latitude, state.zone.longitude];
    }

    return [50, 4];
  },
  queryString(state) {
    const query = {};
    let search = false;
    let q = '';

    if (state.sport !== null) {
      search = true;
      query.what = state.sport;
    }

    if (state.where !== null && state.geocoding) {
      search = true;
      query.where = {
        lat: state.geocoding.lat,
        lng: state.geocoding.lng,
        name: state.zoneName,
      };
    }

    if (search) {
      q = `?q=${encodeURIComponent(JSON.stringify(query))}`;
    }

    return q;
  },
};
