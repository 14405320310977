import { loadAttributes } from '../../services/api/Attributes';
import { getMatches } from '../../services/api/FieldRentalPurchase';

export default {
  async loadMore(context) {
    context.commit('updatePaginationPage', context.state.paginator.page + 1);
    await context.dispatch('loadMatches', { append: true });
  },
  async loadMatches(context, arg) {
    const append = arg?.append ?? null;
    const visibleRange = arg?.range ?? null;
    let options = context.getters.loadMatchesApiOptions;

    // console.debug('loadMatches. Visible date range : ', visibleRange);

    if (visibleRange) {
      options = {
        ...options,
        'dateFrom[after]': visibleRange[0],
        'dateFrom[before]': visibleRange[1],
      };
    }
    // console.debug('Options : %O', options);

    try {
      context.commit('setIsLoading', true);
      const response = await getMatches(options);
      const matches = response['hydra:member'].map((matchGroup) => {
        const m = Object.hasOwnProperty.call(matchGroup, 0) ? matchGroup[0] : matchGroup;
        return {
          ...m,
          center: { ...m.center, distance: matchGroup.distance },
          date_from: new Date(m.date_from),
          date_to: new Date(m.date_to),
        };
      });

      context.commit('updateResults', append ? context.state.results.concat(matches) : matches);
      context.commit('updatePaginationCount', response['hydra:totalItems']);
      context.commit(
        'updatePaginationPageCount',
        Math.ceil(response['hydra:totalItems'] / matches.length)
      );
      context.commit('setIsLoading', false);
      return response;
    } catch (e) {
      console.error(e);
    }

    context.commit('setIsLoading', false);
    return undefined;
  },
  loadAttributes: async (context) => {
    const eq = await loadAttributes();
    context.commit('setAttributes', eq['hydra:member']);
  },
};
