import { sendGetRequest } from './Hydra';
import routes from '../../router/api';
import { objToQueryString } from './helpers';

export async function getAvailabilities(productId, centerId, options = {}) {
  const url = new URL(routes.fieldRental.availabilities(productId), window.location.origin);
  url.search = objToQueryString(options, '');

  try {
    const content = await sendGetRequest(url, {
      Accept: 'application/json',
    });
    // http://sport-finder.local/api/field_rentals/6/availabilities?sport=68&date=2021-07-07

    function leadZeroDate(date) {
      const year = date.getFullYear();
      const month = `${date.getMonth() + 1}`.padStart(2, '0');
      const day = `${date.getDate()}`.padStart(2, '0');
      return [year, month, day].join('-');
    }

    return content?.results?.map((item) => {
      // eslint-disable-next-line no-param-reassign
      item.start = new Date(item.start);
      // eslint-disable-next-line no-param-reassign
      item.end = new Date(item.end);
      // format function is only available w. calendar package
      item.date = Date().format ? item.start.format('YYYY-MM-DD') : leadZeroDate(item.start);
      item.resource = Array.isArray(item.resource)
        ? item.resource.map((r) => r.id)
        : [item.resource.id];
      item.duration = (item.end.valueOf() - item.start.valueOf()) / 1000 / 60;
      item.product = productId;
      return item;
    });
  } catch (e) {
    console.error('FieldRental.getAvailabilities caught %O', e.message);
    throw e;
  }
}

export async function getRentals(options) {
  const url = new URL(routes.fieldRental.fetch, window.location.origin);
  url.search = objToQueryString(options, '');

  // console.debug('FieldRental/getRentals', options, url);
  const response = await sendGetRequest(url);

  // console.debug(response);
  return response;
}

export async function getOverallAvailabilities(resourceId, options) {
  const url = new URL(routes.fieldRental.overallAvailabilities(resourceId), window.location.origin);
  url.search = objToQueryString(options, '');
  // http://sport-finder.local/api/field_rentals/6/overall_availabilities?sport=68&date=2021-07-07
  const content = await sendGetRequest(url, {
    Accept: 'application/json',
  });

  return (Array.isArray(content) ? content : content.results).map((item) => {
    // eslint-disable-next-line no-param-reassign
    item.start = new Date(item.start);
    // eslint-disable-next-line no-param-reassign
    item.end = new Date(item.end);
    return item;
  });
}
